import htmlIcon from "../Asseets/html-5.png";
import cssIcon from "../Asseets/css3.png";
import JSIcon from "../Asseets/js.svg";
import nodejsIcon from "../Asseets/Node.svg"
import ReactIcon from "../Asseets/reactjs.png";
import { Typography ,Grid,Paper} from "@mui/material";

import styles from "./skills.module.css";

const Skills = () => {
  return (
    <section className={styles.skillContainer}>
     

   <div className={styles.gridContainer}>
   <Typography variant="h3" align="center">
        Skill Sets
      </Typography>

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className={styles.grid}>
      <Grid item xs={2} sm={4} md={4}>
      <Paper className={styles.paper}><img src={htmlIcon} className={styles.images} alt="HTML-5"/></Paper>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
    <Paper className={styles.paper}><img src={cssIcon} className={styles.images} alt="CSS3"/></Paper>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
    <Paper className={styles.paper}><img src={JSIcon} className={styles.images} alt="JS"/></Paper>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
    <Paper className={styles.paper}><img src={nodejsIcon} className={styles.images} alt="nodejs"/></Paper>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
    <Paper className={styles.paper}><img src={ReactIcon} className={styles.images} alt="ReactJs"/></Paper>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
    <Paper className={styles.paper}><img src={ReactIcon} className={styles.images}/></Paper>
    </Grid>

</Grid>
</div>
    
    </section>
  );
};

export default Skills;
