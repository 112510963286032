import React from 'react';
import Home from './Components/Home/index';
import styles from "./App.module.css"
import SocialIcon from "./Components/SocialIcons"
import Skills from './Components/Skills';
import Header from "./Components/Nav"


function App() {
  return (
    <div className="App">
 <SocialIcon />

<Header />

<div className={styles.mainContainer}>
<Home />
<Skills />
</div>
   
    
    </div>
  );
}

export default App;
